<template>
  <app-container icon="task_alt" colWidth="col-12 col-lg-10" v-if="!isTextLoading">
    <template #back>
      <app-back
        :routeName="texts.back_link.route"
        :text="texts.back_link.text"
        colWidth="col-12 col-lg-10"
      />
    </template>
    <template #header>
      <h1 class="mb-4 text-dark">{{ texts.title }}</h1>
    </template>
    <template #body>
      <div class="row">
        <div
          class="col-12 col-lg-4"
          v-for="family in families"
          :key="family.key"
        >
          <div class="family-card">
            <img :src="family.img_url" :alt="family.label"/>
            <div class="family-card__content">
              <p>{{ family.label }}</p>
              <button class="btn" @click="handleFamilySelection(family)">
                {{ texts.button }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-container>
</template>

<script>
/**
 * TODO: Add loader
 */

import useTexts from '@/mixins/useTexts';
import Database from '@/utils/services/Database';

export default {
  name: 'Step1Page',
  mixins: [useTexts],
  data() {
    return {
      page: 'configurator_1',
      families: [],
    };
  },
  async created() {
    this.isLoading = true;
    this.families = await Database.getAll('families');
    this.isLoading = false;
  },
  methods: {
    handleFamilySelection(family) {
      localStorage.setItem('product_family', JSON.stringify(family));
      this.$router.push({ name: 'Configurator_Step2' });
    },
  },
};
</script>

<style lang="scss">
.family-card {
  background-color: $light-grey;
  padding-bottom: 2rem;
  border-radius: 1rem;
  img {
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 1rem 1rem 0 0;
  }
  &__content {
    padding: 0 1.5rem;
    p {
      font-weight: bold;
    }
  }
}
</style>
